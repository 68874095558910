import React from 'react';
import './Footer.css';
const LoginFooter = () => {
    return (
      <footer>
        <span className="footerText">
          By using Ava Telepresence apps you accept the{' '}
          <a
            href="https://www.avarobotics.com/terms-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          ,{' '}
          <a
            href="https://www.avarobotics.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Statement
          </a>
          , Notices & Disclaimers.
        </span>
      </footer>
    );
  };



export default LoginFooter;