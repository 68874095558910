// Choice.js

import React, { useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'

const Choice = ({ rmsMatch, username }) => {
	const [serverChoice, setServerChoice] = useState('')
	const formRef = useRef(null)

	const handleChoiceChange = (event) => {
		setServerChoice(event.target.value)
	}

	const handleSubmit = (event) => {
		event.preventDefault()

		if (serverChoice) {
			const selectedRMS = rmsMatch.find(rms => rms === serverChoice)

			if (selectedRMS) {
				window.location.href = `https://${selectedRMS}/Ava8Web/login.htm?username=${encodeURIComponent(username)}`
				console.log(selectedRMS)
			} else {
				console.log('Selected RMS not found')
			}
		}

		setServerChoice('')
		event.target.reset()
	}

	console.log(username, 'this is the username')

	return (
		<div className='outer-container' style={{ maxWidth: '450px', paddingTop: '5%' }}>
			<div className='loginContent' style={{ align: 'center' }}>
				<div className='titlebar'>
					<Image src="newava.png" style={{ width: '150px' }} />
					<div className='page-container' style={{ minHeight: '400px', marginTop: '5%' }}>
						<Card className='bigButton'>
							<Card.Body className='card1'>
								<Form className='form' onSubmit={handleSubmit} ref={formRef} id="myForm">
									<Form.Group className="group" controlId="formBasicEmail">
										<Form.Label className='formtitle' >{`${username} is associated with multiple servers.`}</Form.Label>
									</Form.Group>
									<Form.Select className="custom-select mb-3" aria-label="Default select example" onChange={handleChoiceChange} value={serverChoice}>
										<option value="">Choose server:</option>
										{rmsMatch.map((rms, index) => (
											<option key={index} value={rms}>
												{rms}
											</option>
										))}
									</Form.Select>
								</Form>
							</Card.Body>
							<Button className='submit1' variant="secondary" type="button" onClick={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))} >
								Continue
							</Button>
						</Card>
						<br />
						<Button className='support link'
							variant='link'
							href='https://www.avarobotics.com/customer-support'
							target="_blank"
							rel="noreferrer"
						>
							Contact Support
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Choice