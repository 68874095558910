// App.js

import React, { useState, Fragment } from 'react'
import { Route } from 'react-router-dom'
import { Routes } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import Login from './components/Login'
import Choice from './components/Choice'
import Footer from './components/LoginFooter';

const App = () => {

    // State to hold user and message alerts
    const [user, setUser] = useState(null)
    const [msgAlerts, setMsgAlerts] = useState([])
    const [username, setUsername] = useState('')
    const [rmsMatch, setRmsMatch] = useState([])

    // Log a user and show message
    // console.log('user in app', user)
    // console.log('message alerts', msgAlerts)

    const handleRmsMatch = (rmsMatchData) => {
    setRmsMatch(rmsMatchData)
  }
    
    // Clear out user when logged out
    // eslint-disable-next-line
    const clearUser = () => {
        console.log('clear user ran')
        setUser(null)
    }

    // Auto dismiss alert
    // eslint-disable-next-line
    const deleteAlert = (id) => {
        setMsgAlerts((prevState) => {
            return (prevState.filter((msg) => msg.id !== id) )
        })
    }

    // Alert messages
    const msgAlert = ({ heading, message, variant }) => {
        const id = uuid()
        setMsgAlerts(() => {
            return (
                [{ heading, message, variant, id }]
            )
        })

    }
  console.log(msgAlerts)

        return (
            <Fragment>
                <Routes>
                    <Route path='/' element={<Login msgAlert={msgAlert} user={user} handleRmsMatch={handleRmsMatch} username={username} setUsername={setUsername} />} />
                    <Route path='/choice' element={<Choice msgAlert={msgAlert} user={user} rmsMatch={rmsMatch} username={username} />}/>
                </Routes>
                <Footer />
            </Fragment>
        )
}

export default App